import { engine, download, apPy, backend } from "System/system";
import { notify } from "Redux/user/actions";
import store from "System/mainStore";
import { songHeaders } from "Data/updateHeaders";

// -------------------------------------------------------
// -------------------------------------------------------
export const showPivotPopup = (show) => (dispatch) => {
  dispatch({ type: "SHOW_PIVOT_POPUP", show });
};

// -------------------------------------------------------
// -------------------------------------------------------
export const updatePivot = (data) => (dispatch) => {
  dispatch({ type: "UPDATE_PIVOT", data });
  dispatch(saveProjectAfterTimeout());
};

// -------------------------------------------------------
// -------------------------------------------------------
export const lockProject = (projectid) => (dispatch) => {
  const data = store.getState();
  const { user } = data.user;
  engine("project", "updateLock", { projectid, locked: user });
};
export const unlockProject = (projectid) => (dispatch) => {
  engine("project", "updateLock", { projectid, locked: null });
};

// -------------------------------------------------------
// -------------------------------------------------------
export const getProject = (projectid) => (dispatch) => {
  engine("project", "get", { projectid }).then((result) => {
    if (result.data) {
      const { project } = result.data;
      if (project) {
        if (!project.hasOwnProperty("sourceData")) {
          project.sourceData = [["", ""]];
        }
        if (!project.hasOwnProperty("sourceLink")) {
          project.sourceLink = "";
        }
        if (!project.hasOwnProperty("songData")) {
          project.songData = [["", "", "", "", "", "", "", "", "", "", ""]];
        }
        if (!project.hasOwnProperty("songLink")) {
          project.songLink = "Original_Song_Title_SB";
        }
        if (!project.database) {
          project.database = `${project.title}_${project._id}`;
        }
        if (!project.masterTable) {
          project.masterTable = "Master";
        }
        if (!project.currency) {
          project.currency = {
            eur: 1.09,
            gbp: 1.31,
            cad: 0.81,
            chf: 1.09,
            jpy: 0.0088,
            sek: 0.12,
            dkk: 0.16,
            pln: 0.25,
            aud: 0.75,
            nzd: 0.72,
            hkd: 0.13,
            krw: 0.00073,
            huk: 0.0075,
            czk: 0.045,
          };
        }
        if (project.currency) {
          if (!project.currency.hkd) {
            project.currency.hkd = 0.13;
          }
          if (!project.currency.krw) {
            project.currency.krw = 0.00073;
          }
        }
        if (!project.outputCurrency) {
          project.outputCurrency = "USD";
        }
        if (!project.songHeaders) {
          project.songHeaders = JSON.parse(JSON.stringify(songHeaders));
        }
        if (!project.pivot) {
          project.pivot = project.title;
          project.isPerformer = false;
        }
        if (!project.datadir) {
          project.datadir = `/mnt/disks/birddata2/birddata/${project._id}/`;
        }
        if (!project.excludedSongs) {
          project.excludedSongs = [];
        }
        const colids = Object.keys(project.collectionsRepo);
        let madeChange = false;
        for (var ii = 0; ii < colids.length; ii++) {
          const collection = project.collectionsRepo[colids[ii]];
          if (collection.sql && !collection.sql.mapper) {
            madeChange = true;
            project.collectionsRepo[colids[ii]].sql.mapper = {};
            collection.sql.dbHeaders.forEach((x) => {
              if (x.canonical) {
                project.collectionsRepo[colids[ii]].sql.mapper[x.canonical] =
                  x.header;
              }
            });
          }
        }
        if (madeChange) {
          dispatch({ type: "SET_UNSAVED" });
        }
        console.log("Project Loaded");
        //project.hasFrame = false;
        dispatch({
          type: "SONGBRIDGE_SET_SONG",
          songIndex: null,
          rawSong: null,
          song: null,
          performer: null,
        });
        dispatch({ type: "SET_PROJECT", project });
      }
    }
  });
};

// -------------------------------------------------------
// -------------------------------------------------------
export const newProject = (title, user, history) => (dispatch) => {
  engine("project", "add", {
    user,
    title,
  }).then((result) => {
    const { project } = result.data;

    if (!project.hasOwnProperty("sourceData")) {
      project.sourceData = [["", ""]];
    }
    if (!project.hasOwnProperty("sourceLink")) {
      project.sourceLink = "";
    }
    if (!project.hasOwnProperty("songData")) {
      project.songData = [["", "", "", "", "", "", "", "", "", "", ""]];
    }
    if (!project.hasOwnProperty("songLink")) {
      project.songLink = "Original_Song_Title_SB";
    }
    let database = `${project.title}_${project._id}`;
    if (!project.database) {
      project.database = database;
    }
    if (!project.masterTable) {
      project.masterTable = "Master";
    }
    if (!project.currency) {
      project.currency = {
        eur: 1.17,
        gbp: 1.38,
        cad: 0.81,
        chf: 1.09,
        jpy: 0.0088,
        sek: 0.12,
        dkk: 0.16,
        pln: 0.25,
        aud: 0.75,
        nzd: 0.72,
      };
    }
    if (!project.outputCurrency) {
      project.outputCurrency = "USD";
    }
    engine("database", "initDB", { database });
    dispatch({
      type: "NEW_PROJECT",
      project,
    });
    history.push(`/projects/${project._id}/checkin`);
  });
};

// -------------------------------------------------------
// -------------------------------------------------------
export const listProjects = (user, client) => (dispatch) => {
  dispatch({
    type: "SET_PROJECTS",
    projects: null,
  });

  engine("project", "list", { user, client, howMany: null }).then((result) => {
    const { projects, showMore } = result.data;
    dispatch({
      type: "SET_PROJECTS",
      projects,
      showMore,
    });
  });
};
// -------------------------------------------------------
// -------------------------------------------------------
export const listMoreProjects = (user, howMany) => (dispatch) => {
  engine("project", "list", { user, howMany }).then((result) => {
    const { projects, showMore } = result.data;
    dispatch({
      type: "SET_PROJECTS",
      projects,
      showMore,
    });
  });
};

// -------------------------------------------------------
// -------------------------------------------------------
export const eraseProject = (project) => (dispatch) => {
  const projectid = project._id;
  dispatch({ type: "ERASE_PROJECT", projectid });
  engine("project", "erase", { projectid });
  const path = project.mainDir ? project.mainDir.path : null;
  if (path) {
    backend("project", "erase", { path });
  }
};

// -------------------------------------------------------
// -------------------------------------------------------
export const saveProject = () => (dispatch) => {
  const data = store.getState();
  const { project } = data.projects;
  engine("project", "update", { projectString: JSON.stringify(project) });
  dispatch({ type: "CHANGES_SAVED" });
};

// -------------------------------------------------------
// -------------------------------------------------------
export const saveProjectAfterTimeout = () => (dispatch) => {
  console.log("Saving Project");
  setTimeout(() => {
    dispatch(saveProject());
  }, 3000);
};

// -------------------------------------------------------
// -------------------------------------------------------
export const updateTimestamp = (projectid, cb) => async (dispatch) => {
  const res = await engine("project", "updateTimestamp", { projectid });
  console.log("Result is", res);
  if (res.data) {
    dispatch({
      type: "SET_PROJECT_TIMESTAMP",
      lastUpdated: res.data.lastUpdated,
    });
  }
  cb();
};
