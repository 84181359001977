const initialState = {
};

// -------------------------------------------------------
// -------------------------------------------------------
const reducer = (state = initialState, action) => {
  // -------------------------------------------------------
  switch (action.type) {
    case "SET_CREDITS":
      return {
        ...state,
        credits: action.credits,
      };
    // -------------------------------------------------------
    default:
      return state;
  }
};

export default reducer;
