import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
// -------------------------------------------------------------
import NormingButtons from "../Shared/NormingButtons";
// -------------------------------------------------------------
import { loadNorming, applyTextFilter } from "Redux/norming/actions";
// -------------------------------------------------------------

const datatype = "filter";
// -------------------------------------------------------------
// -------------------------------------------------------------
const Index = () => {
  const dispatch = useDispatch();
  const [remove, setRemove] = useState(false);

  const norming = useSelector((state) => state.norming);

  const { data, unsynced } = norming[datatype];

  useEffect(() => {
    dispatch(loadNorming(datatype));
  }, []);

  return (
    <div>
      {remove ? (
        <RemovePopup
          onClose={() => {
            setRemove(false);
          }}
        />
      ) : null}
      <NormingButtons
        size={data.length}
        unsynced={unsynced}
        datatype={datatype}
      >
        <td>
          <div
            className={`button is-small`}
            onClick={() => {
              setRemove(true);
            }}
          >
            <span className="icon is-small">
              <i className="fa-duotone fa-scalpel-line-dashed" />
            </span>
            <span>Remove String</span>
          </div>
        </td>
      </NormingButtons>
      <MainTable />
    </div>
  );
};
export default Index;

// -------------------------------------------------------------
// -------------------------------------------------------------
const MainTable = ({}) => {
  const dispatch = useDispatch();
  const norming = useSelector((state) => state.norming);
  const { data, predictions } = norming[datatype];
  if (!data) return null;

  return (
    <div className="table-wrapper">
      <table className="table is-striped table-compact">
        <thead>
          <tr>
            <th>#</th>
            <th>Original</th>
            <th>Filtered</th>
          </tr>
        </thead>
        <tbody>
          {data.map((row, rowindex) => (
            <tr key={`row-${rowindex}`}>
              <td>{rowindex + 1}</td>
              <td>{row["Original_Song_Title_SB"]}</td>
              <td>{row["Filtered_Title_SB"]}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

const RemovePopup = ({ onClose }) => {
  const dispatch = useDispatch();
  const [value, setValue] = useState("");
  return (
    <div className="modal is-active">
      <div className="modal-background"></div>
      <div className="modal-card">
        <header className="modal-card-head">
          <p className="modal-card-title">Remove String Snippet</p>
          <button
            className="delete"
            aria-label="close"
            onClick={onClose}
          ></button>
        </header>
        <section className="modal-card-body">
          <input
            className="input"
            placeholder="Enter String to Remove"
            value={value}
            onChange={(evt) => setValue(evt.target.value)}
          />
        </section>
        <footer className="modal-card-foot">
          <button
            className="button is-success"
            disabled={value === ""}
            onClick={() => {
              dispatch(applyTextFilter(value));
              onClose();
            }}
          >
            Remove
          </button>
          <button className="button" onClick={onClose}>
            Cancel
          </button>
        </footer>
      </div>
    </div>
  );
};
