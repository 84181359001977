import React, { useState, useEffect, Fragment } from "react";
import { useSelector, useDispatch } from "react-redux";
// -------------------------------------------------------------
import NormingButtons from "../Shared/NormingButtons";
import ProgressBar from "Components/ProgressBar";
// -------------------------------------------------------------
import {
  setSongDetails,
  songDetails,
  loadNorming,
  doExtractCredits,
} from "Redux/norming/actions";
import { storeCredits, loadCredits } from "Redux/credits/actions";
// -------------------------------------------------------------
import { stem } from "Aux/";
import waitbar from "Media/waitbar.gif";

const datatype = "credits";
// -------------------------------------------------------------
// -------------------------------------------------------------
const Index = () => {
  const dispatch = useDispatch();
  const [loadingNorming, setLoadingNorming] = useState(false);
  const [predicting, setPredicting] = useState(false);
  const [reviewing, setReviewing] = useState(null);
  const norming = useSelector((state) => state.norming);
  const { credits } = useSelector((state) => state.credits);

  const { data, unsynced } = norming[datatype];

  useEffect(() => {
    dispatch(loadCredits());
  }, []);

  const mapping = [];

  const extractCredits = () => {
    setPredicting(true);
    const data = norming[datatype].data;
    const stringSet = new Set();
    data.forEach((row) => {
      if (row["Composer_SB"]) stringSet.add(row["Composer_SB"].trim());
    });
    doExtractCredits(Array.from(stringSet), (results) => {
      setPredicting(false);
      if (results) {
        const { credits } = results;
        const mapping = {};
        credits.forEach((credit) => {
          mapping[credit.string] = credit.credits_last;
        });
        const newData = data.map((row) => {
          row["Credits_SB"] = mapping[row["Composer_SB"]];
          return row;
        });
        dispatch(storeCredits(newData));
      }
    });
  };

  if (!credits) return <img src={waitbar} />;

  return (
    <div>
      <ProgressBar />
      <NormingButtons
        size={data.length}
        unsynced={unsynced}
        datatype={datatype}
      >
        <td>
          <button
            className={`button is-small ${predicting ? "is-loading" : ""}`}
            onClick={extractCredits}
          >
            <span className="icon">
              <i className="fa-duotone fa-user-magnifying-glass"></i>
            </span>
            <span>Extract Credits</span>
          </button>
        </td>
      </NormingButtons>
      <MainTable />
    </div>
  );
};
export default Index;

// -------------------------------------------------------------
// -------------------------------------------------------------
const MainTable = ({ mapping }) => {
  const dispatch = useDispatch();
  const { credits } = useSelector((state) => state.credits);
  if (!credits) return null;

  return (
    <div className="table-wrapper">
      <table className="table is-fullwidth table-compact">
        <thead>
          <tr>
            <th>Cnt</th>
            <th>Song Title</th>
            <th>Credit String</th>
            <th>Parsed Credits</th>
            <th>ISWC</th>
            <th>Performer</th>
          </tr>
        </thead>
        <tbody>
          {credits.map((row, rowindex) => {
            const parsedCredits = row["Credits_SB"] || [];
            return (
              <tr key={`row-${rowindex}`}>
                <td>{rowindex}</td>
                <td>{row["Consensus_Title_SB"]}</td>
                <td>{row["Composer_SB"]}</td>
                <td>
                  <div className="tags">
                    {parsedCredits.map((credit) => (
                      <div key={credit} className="tag is-info is-light">
                        {credit}
                      </div>
                    ))}
                  </div>
                </td>
                <td>{row["SONGID_ISWC_SB"]}</td>
                <td>{row["Release_Artist_9LC"]}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};
